import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {getFarmerStories, getRegions} from "services/upl";
import {Region} from "models/Region";

export function useRegions() {
  return useQuery<Region[]>(["regions"], () => getRegions(), {
    staleTime: 3e6,
    networkMode: "always",
  });
}
