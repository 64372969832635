import { FarmerStory } from "models/FarmerStory";
import "./FarmerStoryDetail.scss";
import CountryCrop from "components/prompts/CountryCrop";
import Back from "components/prompts/Back";
import Footer from "components/Footer";
import { ReactComponent as Sketch1 } from "assets/backgrounds/sketch1.svg";
import { ReactComponent as Sketch2 } from "assets/backgrounds/sketch2.svg";
import { ReactComponent as Sketch3 } from "assets/backgrounds/sketch3.svg";
import { ReactComponent as Sketch4 } from "assets/backgrounds/sketch4.svg";
import { ReactComponent as Sketch5 } from "assets/backgrounds/sketch5.svg";
import { ReactComponent as Sketch6 } from "assets/backgrounds/sketch6.svg";
import { useState } from "react";
import InlineSVG from "react-inlinesvg";

interface FarmerStoryDetailProps {
  farmerStory: FarmerStory;
  farmerStoryIndex?: number;
  onBack?: () => void;
}

function FarmerStoryDetail({
  farmerStoryIndex,
  farmerStory,
  onBack,
}: FarmerStoryDetailProps) {
  const backgroundIndex = (farmerStoryIndex ?? 0) % 4;
  const [sketch] = useState(Math.floor(Math.random() * 6));

  return (
    <div
      className={
        `FarmerStoryDetail story-index-${backgroundIndex}` +
        (!onBack ? " no-back-btn" : "")
      }
    >
      <div
        className={
          "left-panel animate__animated animate__slideInLeft animate__faster"
        }
      >
        <div className={"content"}>
          {onBack && <Back onClick={onBack} light />}
          <div className={"photo"}>
            <img src={farmerStory.story_image} alt={""} />
          </div>
          <CountryCrop country={farmerStory.country} crop={farmerStory.crop} />
          <div className={"farmer-name"}>{farmerStory.farmer_name}</div>
          <div
            className={"summary"}
            dangerouslySetInnerHTML={{ __html: farmerStory.summary }}
          />
        </div>
        <div className={"backdrop"} />
        <div className={"illustration"}>
          {sketch === 0 && <Sketch1 />}
          {sketch === 1 && <Sketch2 />}
          {sketch === 2 && <Sketch3 />}
          {sketch === 3 && <Sketch4 />}
          {sketch === 4 && <Sketch5 />}
          {sketch === 5 && <Sketch6 />}
        </div>
      </div>
      <div
        className={
          "story  animate__animated animate__slideInUp animate__faster"
        }
      >
        <div className={"crop"}>
          <InlineSVG src={farmerStory.crop.image} />
        </div>
        <div dangerouslySetInnerHTML={{ __html: farmerStory.story_details }} />
      </div>
      <Footer dark>
        <div className={"important"}>Real stories, real world impact</div>
        <div className={"important"}>Reimagining Sustainability</div>
        <div>with UPL Farmer Heroes</div>
      </Footer>
    </div>
  );
}
export default FarmerStoryDetail;
