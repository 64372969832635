import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { getFarmerStories } from "services/upl";
import { FarmerStory } from "models/FarmerStory";

export function useFarmerStories() {
  return useQuery<FarmerStory[]>(["farmer-stories"], () => getFarmerStories(), {
    staleTime: 3e6,
    networkMode: "always",
  });
}
