import { Country } from "models/Country";
import { Crop } from "models/Crop";
import InlineSVG from "react-inlinesvg";
import "./CountryCrop.scss";

interface CountryCropProps {
  country: Country;
  crop: Crop;
  inverseCrop?: boolean;
}
function CountryCrop({ country, crop, inverseCrop = false }: CountryCropProps) {
  return (
    <div className={"CountryCrop" + (inverseCrop ? " inverse-crop" : "")}>
      <div className={"country"}>
        <div className={"icon"}>
          <InlineSVG src={`/flags/${country.iso2.toLowerCase()}.svg`} />
        </div>
        <div className={"name"}>{country.name}</div>
      </div>
      <div className={"crop"}>
        <div
          className={"icon"}
          style={inverseCrop ? { backgroundColor: crop.color } : {}}
        >
          <InlineSVG src={crop.image} />
        </div>
        <div className={"name"}>{crop.name}</div>
      </div>
    </div>
  );
}

export default CountryCrop;
