import {ReactNode} from "react";
import PickerButton from "components/PickerButton";

export interface PickerProps {
  pickerIcon: ReactNode;
  pickerLeft: number;
  active?: boolean;
  onActivate: () => void;
  onActivateCallback?: () => void;
  
  className?: string;
  children?: ReactNode;
}

function Picker ({active, pickerIcon, pickerLeft, className = '', onActivate, onActivateCallback, children}: PickerProps) {

  const handleClick = () => {
    onActivate();
    if (onActivateCallback) onActivateCallback();
  }

  return <div className={"Picker " + className}>
    <PickerButton onClick={handleClick} left={pickerLeft} active={active}>{pickerIcon}</PickerButton>
    {active && children}
  </div>
}

export default Picker;