import { useParams } from "react-router-dom";
import languages from "data/languages.json";
import { DefaultLanguage } from "contexts";
import { useEffect, useState } from "react";
import { loadLanguageResource } from "services/language";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import allLanguages from "data/languages.json";
import { Language } from "models/Language";

i18n.use(initReactI18next).init({
  lng: allLanguages[0].id,
  interpolation: {
    escapeValue: false,
  },
});

export function useLanguageResourceLoader(): [Language, boolean] {
  const { lang } = useParams();
  const language = languages.find((l) => l.id === lang) ?? DefaultLanguage;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      setLoaded(false);
      const resources = await loadLanguageResource(language.id);
      i18n.addResourceBundle(language.id, "translation", resources);
      await i18n.changeLanguage(language.id);
      setLoaded(true);
    })();
  }, [language]);

  return [language, loaded];
}
