import "./Back.scss";
import { ReactComponent as LeftArrow } from "./left-arrow.svg";

interface BackProps {
  onClick?: () => void;
  light?: boolean;
}
function Back({ onClick, light = false }: BackProps) {
  return (
    <button className={"Back" + (light ? " light" : "")} onClick={onClick}>
      <LeftArrow />
      <span>Back</span>
    </button>
  );
}

export default Back;
