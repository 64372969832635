import Pin, { PinProps } from "components/map/Pin";
import { useEffect, useRef } from "react";

interface RegionPinProps extends PinProps {}

function RegionPin({ ...props }: RegionPinProps) {
  const { pointOfInterest } = props;
  const { x, y, color } = pointOfInterest;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let intv: NodeJS.Timeout;
    if (ref.current) {
      if (pointOfInterest.type === "region") {
        ref.current.style.top = "-150px";
        ref.current.style.left = `${x}%`;
        intv = setTimeout(() => {
          if (ref.current) {
            ref.current.style.top = `${y}%`;
          }
        }, Math.random() * 300);

        // re-calculate the name box
        if (ref.current.firstChild) {
          const nameNode = ref.current.firstChild as HTMLDivElement;
          nameNode.style.marginLeft = `-${nameNode.clientWidth / 2}px`;
        }
      } else {
        ref.current.style.left = `${x}%`;
        ref.current.style.top = `${y}%`;
      }
    }
    return () => {
      if (intv) {
        clearInterval(intv);
      }
    };
  }, []);

  return (
    <Pin {...props} className={"RegionPin"} ref={ref}>
      <div className={"name"}>{pointOfInterest.name}</div>
      <div
        className={"marker"}
        style={{ backgroundColor: color ?? "#ed1f34" }}
      />
      <div className={"beacon"}>
        <div
          className={"before"}
          style={{
            boxShadow: color ? `0 0 0 2px ${color}` : "0 0 0 2px #e8710e",
          }}
        />
        <div
          className={"after"}
          style={{
            boxShadow: color ? `0 0 0 2px ${color}` : "0 0 0 2px #e8710e",
          }}
        />
      </div>
    </Pin>
  );
}

export default RegionPin;
