import { ReactComponent as Map } from "assets/globe.svg";
import Picker, { PickerProps } from "components/Picker";
import { useContext, useEffect } from "react";
import { PointOfInterestContext, PointOfInterestContextType } from "contexts";
import regions from "data/regions.json";

interface RegionPickerProps
  extends Omit<PickerProps, "children" | "pickerIcon" | "pickerLeft"> {}
function RegionPicker({ ...props }: RegionPickerProps) {
  const { setPointOfInterests } = useContext<PointOfInterestContextType>(
    PointOfInterestContext,
  );
  useEffect(() => {
    if (props.active) {
      setPointOfInterests(
        regions.map((region) => ({
          ...region,
          id: `reg-${region.id}`,
          type: "region",
        })),
      );
    }
  }, [props.active]);
  return <Picker {...props} pickerIcon={<Map />} pickerLeft={24} />;
}

export default RegionPicker;
