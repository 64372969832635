import "./SearchPanel.scss";
import Pop from "components/Pop";
import { FarmerStory } from "models/FarmerStory";
import InlineSVG from "react-inlinesvg";
import { ReactComponent as Pin } from "./pin.svg";

interface SearchPanelProps {
  searched: boolean;
  searching: boolean;
  focusedCandidate: FarmerStory | null;
  candidates: FarmerStory[];
  onCandidate: (candidate: FarmerStory) => void;
}
function SearchPanel({
  searched,
  searching,
  focusedCandidate,
  candidates,
  onCandidate,
}: SearchPanelProps) {
  return (
    <Pop className={"SearchPanel"} preventClick>
      {!searching && searched && candidates.length === 0 && (
        <div className={"empty"}>Nothing is found</div>
      )}
      {!searching && searched && candidates.length > 0 && (
        <div className={"results"}>
          {candidates.map((candidate) => (
            <div
              className={
                "farmer " + (focusedCandidate === candidate ? " active" : "")
              }
              key={candidate.id}
              onClick={() => onCandidate(candidate)}
            >
              {candidate.crop?.image && (
                <div
                  className={"crop-image"}
                  style={{ backgroundColor: candidate.crop.color }}
                >
                  <InlineSVG
                    src={candidate.crop.image}
                    width={48}
                    height={48}
                  />
                </div>
              )}
              <div className={"info"}>
                <div className={"name-country"}>
                  <div className={"name"}>{candidate.farmer_name}</div>
                  <div className={"country-crop"}>
                    <div className={"country"}>
                      <span className={"pin"}>
                        <Pin />
                      </span>
                      <span>{candidate.country.name}</span>
                    </div>
                    <div className={"crop"}>{candidate.crop.name}</div>
                  </div>
                </div>
                <div className={"bio"}>
                  {candidate.summary.replace(/(<([^>]+)>)/gi, "")}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </Pop>
  );
}

export default SearchPanel;
