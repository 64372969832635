import { useState } from "react";
import SearchInput from "components/search/SearchInput";
import SearchPanel from "components/search/SearchPanel";
import "./Search.scss";
import { useFarmerStories } from "hooks/useFarmerStories";
import { FarmerStory } from "models/FarmerStory";
import Fuse from "fuse.js";
import SearchResultPanel from "components/search/SearchResultPanel";
import Overlay from "components/Overlay";
import fs from "screenfull";

interface SearchProps {
  onLogoClick?: () => void;
}
function Search({ onLogoClick }: SearchProps) {
  const { data: farmerStories } = useFarmerStories();

  const [candidates, setCandidates] = useState<FarmerStory[]>([]);
  const [search, setSearch] = useState("");
  const [searching, setSearching] = useState(false);
  const [active, setActive] = useState(false);
  const [candidate, setCandidate] = useState<FarmerStory | null>(null);

  // const onClickOutside = useCallback(() => {
  //   setActive(false);
  // }, []);

  // const ref = useClickOutside<HTMLDivElement>(onClickOutside);

  const handleSearch = (search: string) => {
    setSearch(search);

    if (farmerStories) {
      // Then we use fuse to search
      // https://fusejs.io/api/options.html
      const fuse = new Fuse<FarmerStory>(farmerStories, {
        includeScore: true,
        threshold: 0.2,
        keys: ["farmer_name", "crop.name", "country.name", "summary"],
        fieldNormWeight: 1,
      });
      const result = fuse.search(search).map((r) => r.item);
      setCandidates(result);
    }
  };

  // currently only use for full screen
  const handleEnter = () => {
    if (search === "fullscreen" && fs.isEnabled) {
      (async () => {
        if (fs.isFullscreen) await fs.exit();
        else await fs.request();
        setSearch("");
        setActive(false);
      })();
    }
  };

  const handleClear = () => {
    setSearch("");
  };

  const handleFocus = () => {
    setActive(true);
  };

  const handleCandidateClick = (candidate: FarmerStory) => {
    setCandidate(candidate);
  };

  const handleLogoClick = () => {
    setCandidate(null);
    setActive(false);
    if (onLogoClick) onLogoClick();
  };

  return (
    <div className={"Search " + (active ? "active" : "")}>
      {active && <Overlay onClick={() => setActive(false)} />}
      <SearchInput
        active={active}
        search={search}
        onFocus={handleFocus}
        onBlur={() => {}}
        onClear={handleClear}
        onSearch={handleSearch}
        onEnter={handleEnter}
      />
      {active && (
        <SearchPanel
          focusedCandidate={candidate}
          searched={!!search}
          searching={searching}
          candidates={candidates}
          onCandidate={handleCandidateClick}
        />
      )}
      {candidate && (
        <SearchResultPanel
          onClose={() => setCandidate(null)}
          farmerStoryIndex={candidates.indexOf(candidate)}
          farmerStory={candidate}
          onLogoClick={handleLogoClick}
        />
      )}
      {/*{active && (*/}
      {/*  <div className={"close"} onClick={() => setActive(false)}>*/}
      {/*    <Close />*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
}

export default Search;
