import { MouseEvent, ReactNode, forwardRef } from "react";
import "./Pop.scss";

interface PopProps {
  children?: ReactNode;
  className?: string;
  preventClick?: boolean;
}

const Pop = forwardRef<HTMLDivElement, PopProps>(
  ({ children, className, preventClick = false }, ref) => {
    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
      if (preventClick) {
        e.stopPropagation();
        e.preventDefault();
      }
    };

    return (
      <div
        ref={ref}
        className={("Pop " + className).trim()}
        onClick={handleClick}
      >
        {children}
      </div>
    );
  },
);

export default Pop;
