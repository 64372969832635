import Map from "components/map/Map";
import { useLanguageResourceLoader } from "hooks/useLanguageResourceLoader";
import RegionPicker from "components/region-picker/RegionPicker";
import CropsPicker from "components/crops-picker/CropsPicker";
import { MapContext, PointOfInterestContext } from "contexts";
import { useState } from "react";
import { PointOfInterest, PointOfInterestType } from "models/PointOfInterest";
import Search from "components/search/Search";
import CountryPrompt from "components/prompts/country/CountryPrompt";
import CropPrompt from "components/prompts/crop/CropPrompt";
import "animate.css";
import Footer from "components/Footer";
import { css } from "@emotion/css";
import Logo from "components/Logo";

function Home() {
  useLanguageResourceLoader();
  const [mapContext, setMapContext] = useState<PointOfInterestType>("region");
  const [pointOfInterests, setPointOfInterests] = useState<PointOfInterest[]>(
    [],
  );
  const [showDirectory, setShowDirectory] = useState(false);

  const [pointOfInterest, setPointOfInterest] =
    useState<PointOfInterest | null>(null);

  const handlePointOfInterest = (pointOfInterest: PointOfInterest | null) => {
    setPointOfInterest(pointOfInterest);
    if (pointOfInterest) {
      setShowDirectory(true);
    }
  };

  const handleDirectoryClose = () => {
    setShowDirectory(false);
  };

  const handleRegionPickerActivate = () => {
    if (mapContext !== "region") {
      setMapContext("region");
    } else {
      setPointOfInterest(null);
      setShowDirectory(true);
    }
  };

  const handleCropPickerActivate = () => {
    setMapContext("crop");
    // if (mapContext !== "crop") {
    //   setMapContext("crop");
    // } else {
    //   setPointOfInterest(null);
    //   setShowDirectory(true);
    // }
  };

  const handleReset = () => {
    setMapContext("region");
    setShowDirectory(false);
  };

  return (
    <MapContext.Provider value={mapContext}>
      <PointOfInterestContext.Provider
        value={{ pointOfInterests, setPointOfInterests }}
      >
        {showDirectory && mapContext === "region" && (
          <CountryPrompt
            pointOfInterest={pointOfInterest}
            onClose={handleDirectoryClose}
            onLogoClick={handleReset}
          />
        )}
        {showDirectory && mapContext === "crop" && (
          <CropPrompt
            pointOfInterest={pointOfInterest}
            onClose={handleDirectoryClose}
            onLogoClick={handleReset}
          />
        )}
        <div
          className={css`
            position: fixed;
            top: 24px;
            left: 24px;
            line-height: 1;
            z-index: 40;
          `}
        >
          <Logo onClick={handleReset} />
        </div>
        <Footer />
        {!showDirectory && (
          <>
            <Search onLogoClick={handleReset} />
            <RegionPicker
              active={mapContext === "region"}
              onActivate={handleRegionPickerActivate}
            />
            <CropsPicker
              active={mapContext === "crop"}
              onActivate={handleCropPickerActivate}
            />
          </>
        )}
        <Map
          loading={false}
          showPin={!showDirectory}
          pointOfInterests={pointOfInterests}
          onPointOfInterest={handlePointOfInterest}
        />
      </PointOfInterestContext.Provider>
    </MapContext.Provider>
  );
}

export default Home;
