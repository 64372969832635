import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { getCrops, getFarmerStories } from "services/upl";
import { Crop } from "models/Crop";

export function useCrops() {
  return useQuery<Crop[]>(["crops"], () => getCrops(), {
    staleTime: 3e6,
    networkMode: "always",
  });
}
