import Prompt from "components/Prompt";
import { useCountries } from "hooks/useCountries";
import { useEffect, useMemo, useState } from "react";
import { Country } from "models/Country";
import { FarmerStory } from "models/FarmerStory";
import CountryListing from "./CountryListing";
import FarmerListing from "components/prompts/FarmerListing";
import FarmerStoryDetail from "components/prompts/FarmerStoryDetail";
import { PointOfInterest } from "models/PointOfInterest";
import Footer from "components/Footer";

interface CountryPromptProps {
  pointOfInterest?: PointOfInterest | null;
  onClose?: () => void;
  onLogoClick?: () => void;
}
function CountryPrompt({
  onClose,
  onLogoClick,
  pointOfInterest: refPointOfInterest,
}: CountryPromptProps) {
  const [pointOfInterest, setPointOfInterest] = useState<
    PointOfInterest | null | undefined
  >(refPointOfInterest);
  const [country, setCountry] = useState<Country | null>(null);
  const [farmerStory, setFarmerStory] = useState<FarmerStory | null>(null);
  const [farmerStoryIndex, setFarmerStoryIndex] = useState(0);

  const handleFarmerStory = (farmerStory: FarmerStory, index: number) => {
    setFarmerStoryIndex(index);
    setFarmerStory(farmerStory);
  };

  const handleCountry = (country: Country) => {
    setCountry(country);
  };

  const handleFarmerListingClose = () => {
    setCountry(null);
    // clear after to avoid keep scrolling
    setPointOfInterest(null);
  };

  return (
    <Prompt
      className={"CountryPrompt"}
      onClose={onClose}
      onLogoClick={onLogoClick}
    >
      {country ? (
        <>
          {farmerStory ? (
            <FarmerStoryDetail
              farmerStory={farmerStory}
              farmerStoryIndex={farmerStoryIndex}
              onBack={() => setFarmerStory(null)}
            />
          ) : (
            <FarmerListing
              country={country}
              onFarmerStory={handleFarmerStory}
              onBack={handleFarmerListingClose}
            />
          )}
        </>
      ) : (
        <CountryListing
          onCountry={handleCountry}
          pointOfInterest={pointOfInterest}
        />
      )}
    </Prompt>
  );
}

export default CountryPrompt;
