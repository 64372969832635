import { KeyboardEvent, ChangeEvent, FormEvent, useRef } from "react";
import { useTranslation } from "react-i18next";

interface SearchInputProps {
  search: string;
  active: boolean;
  onFocus: () => void;
  onBlur: () => void;
  onSearch: (search: string) => void;
  onClear: () => void;
  onEnter: () => void;
}
function SearchInput({
  search,
  active,
  onSearch,
  onFocus,
  onBlur,
  onClear,
  onEnter,
}: SearchInputProps) {
  const { t } = useTranslation();
  const input = useRef<HTMLInputElement>(null);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onSearch(e.currentTarget.value);
  };

  const handleInput = (e: FormEvent) => {
    if ((e.currentTarget as HTMLInputElement).value === "") {
      onClear();
      // input.current?.blur();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onEnter();
    }
  };

  return (
    <div className={"SearchInput"}>
      <input
        ref={input}
        type={"search"}
        value={search}
        onClick={(e) => e.stopPropagation()}
        onChange={handleChange}
        onFocus={() => onFocus()}
        onBlur={() => onBlur()}
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        className={"input" + (active ? " active" : "")}
        spellCheck={false}
        autoCapitalize={"off"}
        autoComplete={"off"}
        placeholder={t("dashboard.search_farmer")}
      />
    </div>
  );
}

export default SearchInput;
