import { FarmerStory } from "models/FarmerStory";
import "./SearchResultPanel.scss";
import { useEffect, useRef } from "react";
import Prompt from "components/Prompt";
import FarmerStoryDetail from "components/prompts/FarmerStoryDetail";

interface SearchResultPanelProps {
  farmerStory: FarmerStory;
  farmerStoryIndex: number;
  onClose: () => void;
  onLogoClick?: () => void;
}
function SearchResultPanel({
  farmerStory,
  farmerStoryIndex,
  onClose,
  onLogoClick,
}: SearchResultPanelProps) {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (farmerStory && ref.current) {
      ref.current.scrollTop = 0;
    }
  }, [farmerStory]);
  return (
    <Prompt onClose={onClose} onLogoClick={onLogoClick}>
      <FarmerStoryDetail
        farmerStory={farmerStory}
        farmerStoryIndex={farmerStoryIndex}
      />
    </Prompt>
    // <div className={"SearchResultPanel"} ref={ref}>
    //   <Close onClick={onClose} />
    //   <FarmerPanel farmerStory={farmerStory} />
    // </div>
  );
}

export default SearchResultPanel;
