import React from "react";
import { DefaultLanguage } from "contexts";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "pages/Home";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // a week. or Infinity to persist indefinitely
      cacheTime: 1000 * 60 * 60 * 24 * 7,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path={"/:lang/*"} element={<Home />} />
          <Route
            path={"*"}
            element={<Navigate to={`/${DefaultLanguage.id}`} replace />}
          />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
