export async function loadLanguageResource(id: string): Promise<object> {
  // // we are not pulling files for now, but mock the json loadings
  // switch (id) {
  //   case "es-ES":
  //     return Promise.resolve(esES);
  //   case "pt-PT":
  //     return Promise.resolve(ptPT);
  //   case "en-US":
  //   default:
  //     return Promise.resolve(enUS);
  // }
  const res = await window.fetch(`/lang/${id}.json`);
  return await res.json();
}
