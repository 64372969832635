import { Crop } from "models/Crop";
import { FarmerStory } from "models/FarmerStory";
import { Country } from "models/Country";
import { Region } from "models/Region";
//
// export async function getFarmerStories() {
//   let currentPage = 1;
//   let hasMore = false;
//   const limit = 200;
//   let farmerStories: FarmerStory[] = [];
//
//   do {
//     try {
//       const { data } = await get(`farmer-story?limit=${limit}`);
//       const { data: pagedFarmerStories, meta } = data;
//       currentPage = meta.pagination.current_page;
//       hasMore = meta.pagination.total_pages > currentPage;
//       farmerStories = [...farmerStories, ...pagedFarmerStories];
//       ++currentPage;
//     } catch {
//       // continue;
//     }
//   } while (hasMore);
//
//   return farmerStories;
// }
//
// export async function getRegions() {}
//
// export async function getCountries() {}
//
// export async function getCrops(): Promise<Crop[]> {
//   const { data } = await get(`crops`);
//   return data;
// }

async function getJsonResource<T>(url: string): Promise<T> {
  const res = await window.fetch(url);
  return await res.json();
}

export async function getFarmerStories() {
  const farmerStories = await getJsonResource<FarmerStory[]>(
    "/cache/farmer-stories.json",
  );
  // on Oct 21, the crop, country and region suddenly has an additional "data" field.
  return farmerStories.map((farmerStory: any) => {
    const { crop, country, region, ...others } = farmerStory;
    return {
      crop: crop.data,
      country: country.data,
      region: region.data,
      ...others,
    };
  });
}

export async function getCrops() {
  const crops: Crop[] = await getJsonResource<Crop[]>("/cache/crops.json");
  return crops.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
}

export async function getRegions() {
  const regions = await getJsonResource<Region[]>("/cache/regions.json");
  return regions.sort((a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });
}

export async function getCountries() {
  const countries: Country[] = await getJsonResource<Country[]>(
    "/cache/countries.json",
  );
  return countries.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
}
