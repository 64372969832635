import "./Pin.scss";
import { PointOfInterest } from "models/PointOfInterest";
import { MouseEvent, ReactNode, useRef, forwardRef } from "react";

export interface PinProps {
  className?: string;
  pointOfInterest: PointOfInterest;
  onClick?: (pointOfInterest: PointOfInterest) => void;
  children?: ReactNode;
}

const Pin = forwardRef<HTMLDivElement, PinProps>(
  ({ pointOfInterest, onClick, children, className }, ref) => {
    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
      // e.preventDefault();
      // e.stopPropagation();
      if (onClick) {
        onClick(pointOfInterest);
      }
    };

    return (
      <div ref={ref} className={"Pin " + className} onClick={handleClick}>
        {children}
      </div>
    );
  },
);

export default Pin;
