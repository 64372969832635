import { useQuery } from "@tanstack/react-query";
import { getCountries } from "services/upl";
import { Country } from "models/Country";

export function useCountries() {
  return useQuery<Country[]>(["countries"], () => getCountries(), {
    staleTime: 3e6,
    networkMode: "always",
  });
}
