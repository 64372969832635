import "./Map.scss";
import { MouseEvent, useRef } from "react";
import { PointOfInterest } from "models/PointOfInterest";
import InlineSVG from "react-inlinesvg";
import RegionPin from "components/map/RegionPin";
import CropPin from "components/map/CropPin";

class Point {
  constructor(public left: number, public top: number) {}

  public within(nw: Point, se: Point): boolean {
    return (
      this.left >= nw.left &&
      this.top >= nw.top &&
      this.left <= se.left &&
      this.top <= se.top
    );
  }
}

interface MapProps {
  loading: boolean;
  showPin?: boolean;
  pointOfInterests?: PointOfInterest[];
  onPointOfInterest?: (pointOfInterest: PointOfInterest | null) => void;
}

function Map({
  loading,
  pointOfInterests = [],
  showPin = true,
  onPointOfInterest,
}: MapProps) {
  const mapRef = useRef<HTMLDivElement>(null);
  const internalRef = useRef<HTMLDivElement>(null);
  const clickOverlayRef = useRef<HTMLDivElement>(null);
  // const ref = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (ref.current) {
  //     const bounds: [number, number][] = [
  //       [0, 0],
  //       [800, 1920],
  //     ];
  //     const map = L.map(ref.current, {
  //       crs: L.CRS.Simple,
  //       maxZoom: 10,
  //       minZoom: 0,
  //       maxBounds: bounds,
  //     });
  //     L.imageOverlay("/map.svg", bounds).addTo(map);
  //     map.fitBounds(bounds);
  //   }
  // }, []);

  // return <div ref={ref} className={"Map"} />;

  const drawHitBox = (nw: Point, diameter: number) => {
    mapRef.current!.querySelectorAll(".hitbox").forEach((box) => {
      box.remove();
    });

    // draw an unmanned box
    const box = document.createElement("div");
    box.className = "hitbox";
    box.style.backgroundColor = "rgba(255, 0, 0, 0.2)";
    box.style.position = "absolute";
    box.style.left = `${nw.left}px`;
    box.style.top = `${nw.top}px`;
    box.style.width = `${diameter}px`;
    box.style.height = `${diameter}px`;
    mapRef.current!.appendChild(box);
  };

  const handleMapTap = (e: MouseEvent<HTMLDivElement>) => {
    // hit box siam-algorithm

    const hit = new Point(
      // change at the Map.scss:5 too
      e.clientX + window.innerWidth * 0.03,
      e.clientY - window.innerHeight * 0.1,
    );
    // must be at least larger than the pin
    const diameter = 60;
    const radius = diameter / 2;
    // Draw a box, and find the items being hit
    const nw = new Point(hit.left - radius, hit.top - radius);
    const se = new Point(hit.left + radius, hit.top + radius);

    // drawHitBox(nw, diameter);

    // we scan through to find the points that being visually hit
    const hitPins: HTMLDivElement[] = [];
    internalRef.current!.querySelectorAll(".Pin").forEach((p) => {
      const pin = p as HTMLDivElement;
      const pinNw = new Point(pin.offsetLeft, pin.offsetTop);
      const pinNe = new Point(pin.offsetLeft + pin.clientWidth, pin.offsetTop);
      const pinSw = new Point(pin.offsetLeft, pin.offsetTop + pin.clientHeight);
      const pinSe = new Point(
        pin.offsetLeft + pin.clientWidth,
        pin.offsetTop + pin.clientHeight,
      );

      // If the pin's any corner is within the boundary
      if (
        pinSe.within(nw, se) ||
        pinNw.within(nw, se) ||
        pinSw.within(nw, se) ||
        pinNe.within(nw, se)
      ) {
        hitPins.push(pin);
      }
    });

    // Reset first
    Array.from(internalRef.current!.querySelector(".pins")!.children).forEach(
      (p) => {
        const pin = p as HTMLDivElement;
        pin.style.left = pin.dataset.originLeft || pin.style.left;
        pin.style.top = pin.dataset.originTop || pin.style.top;
      },
    );

    if (hitPins.length > 1) {
      const projection = 90;
      const count = hitPins.length;
      hitPins.forEach((pin, i) => {
        pin.dataset.originLeft = pin.dataset.originLeft || pin.style.left;
        pin.dataset.originTop = pin.dataset.originTop || pin.style.top;

        const left =
          hit.left +
          radius / 4 +
          projection *
            Math.sin((Math.PI * 2 * (90 + (360 / count) * i)) / 360) -
          radius;
        const top =
          hit.top +
          radius / 4 +
          projection *
            Math.cos((Math.PI * 2 * (90 + (360 / count) * i)) / 360) -
          radius;

        pin.style.left = `${Math.min(
          Math.max(0, left),
          window.innerWidth - 20,
        )}px`;
        pin.style.top = `${Math.min(
          Math.max(0, top),
          window.innerHeight - 20,
        )}px`;
      });
      // siam kai!
    } else if (hitPins.length === 1) {
      // just trigger the tap
      const idx = Array.from(
        internalRef.current!.querySelector(".pins")!.children,
      ).indexOf(hitPins[0]);
      if (onPointOfInterest) {
        onPointOfInterest(pointOfInterests[idx] ?? null);
      }
    }
  };

  // const handleMapLocationFinder = (e: MouseEvent<HTMLDivElement>) => {
  //   // const x =
  //   //   Math.round((e.clientX / e.currentTarget.clientWidth) * 10000) / 100;
  //   // const y =
  //   //   Math.round((e.clientY / e.currentTarget.clientHeight) * 10000) / 100;
  //   // console.log(x, y);
  //   // if (onPointOfInterest) {
  //   //   onPointOfInterest(null);
  //   // }
  // };

  const handleOnPointOfInterest = (pointOfInterest: PointOfInterest) => {
    // const screenWidth = window.innerWidth;
    // const screenHeight = window.innerHeight;
    // const originX = (pointOfInterest.x / 100) * screenWidth;
    // const originY = (pointOfInterest.y / 100) * screenHeight;
    // const targetY = screenHeight / 2 - originY;
    // // zooming
    // if (originX < screenWidth / 2) {
    //   const targetX = originX - screenWidth / 4;
    //   panInto(targetX, targetY);
    // } else {
    //   const targetX = originX - (screenWidth / 4) * 3;
    //   panInto(targetX, targetY);
    // }

    if (onPointOfInterest) {
      onPointOfInterest(pointOfInterest);
    }
  };

  return (
    <div
      className={
        "Map " + (loading ? "loading" : "") + (!showPin ? "hide-pin" : "")
      }
      onClick={handleMapTap}
      ref={mapRef}
    >
      <div className={"loading-box"}>Loading Up...</div>
      <div ref={clickOverlayRef} className={"click-overlay"} />
      <div className={"internal"} ref={internalRef}>
        <div className={"pins"}>
          {pointOfInterests.map((poi) =>
            poi.type === "region" ? (
              <RegionPin
                pointOfInterest={poi}
                key={poi.id}
                onClick={handleOnPointOfInterest}
              />
            ) : (
              <CropPin
                pointOfInterest={poi}
                key={poi.id}
                onClick={handleOnPointOfInterest}
              />
            ),
          )}
        </div>
        <InlineSVG className="map" src={"/map.svg"} />
      </div>
    </div>
  );
}

export default Map;
