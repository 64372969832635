import { Country } from "models/Country";
import { Crop } from "models/Crop";
import { useFarmerStories } from "hooks/useFarmerStories";
import { useMemo, useRef } from "react";
import { FarmerStory } from "models/FarmerStory";
import "./FarmerListing.scss";
import Back from "components/prompts/Back";
import FarmerListingCard from "components/prompts/FarmerListingCard";
import Footer from "components/Footer";

interface FarmerListingProps {
  country?: Country | null;
  crop?: Crop;
  onFarmerStory: (farmerStory: FarmerStory, index: number) => void;
  onBack?: () => void;
}

function FarmerListing({
  onFarmerStory,
  country,
  crop,
  onBack,
}: FarmerListingProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { data: allFarmerStories } = useFarmerStories();

  const farmerStories = useMemo<FarmerStory[]>(() => {
    if (allFarmerStories) {
      if (country && crop) {
        return allFarmerStories.filter(
          (farmerStory) =>
            farmerStory.country.id === country.id &&
            farmerStory.crop.id === crop.id,
        );
      } else if (country) {
        return allFarmerStories.filter(
          (farmerStory) => farmerStory.country.id === country.id,
        );
      } else if (crop) {
        return allFarmerStories.filter(
          (farmerStory) => farmerStory.crop.id === crop.id,
        );
      }
    }
    return [];
  }, [allFarmerStories]);

  return (
    <div
      className={"FarmerListing" + (!onBack ? " no-back-btn" : "")}
      ref={ref}
    >
      {onBack && <Back onClick={onBack} />}
      <div className={"container"}>
        <div className={"listing"}>
          {farmerStories.map((farmerStory, i) => (
            <FarmerListingCard
              farmerStory={farmerStory}
              key={farmerStory.id}
              onClick={() => onFarmerStory(farmerStory, i)}
            />
          ))}
        </div>
      </div>
      <Footer dark>
        <div className={"important"}>Advancing agriculture</div>
        <div>with UPL Farmer Heroes</div>
      </Footer>
    </div>
  );
}

export default FarmerListing;
