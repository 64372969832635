import InlineSVG from "react-inlinesvg";
import Pin, { PinProps } from "components/map/Pin";
import { useEffect, useRef } from "react";
import "./CropPin.scss";

interface CropPinProps extends PinProps {}

function CropPin(props: CropPinProps) {
  const { pointOfInterest } = props;
  const { x, y, color } = pointOfInterest;
  const ref = useRef<HTMLDivElement>(null);
  const cropRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.left = `${x}%`;
      ref.current.style.top = `${y}%`;
      setTimeout(() => {
        if (ref.current) {
          ref.current.style.transform = "scale(1)";
        }
      }, Math.random() * 300);
    }
    if (cropRef.current && color) {
      cropRef.current.style.backgroundColor = color;
    }
  }, []);
  return (
    <Pin {...props} className={"CropPin"} ref={ref}>
      <div className={"crop"} ref={cropRef}>
        {pointOfInterest.icon && <InlineSVG src={pointOfInterest.icon} />}
      </div>
    </Pin>
  );
}

export default CropPin;
