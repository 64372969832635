import { FarmerStory } from "models/FarmerStory";
import CountryCrop from "components/prompts/CountryCrop";
import "./FarmerListingCard.scss";

interface FarmerListingCardProps {
  onClick?: () => void;
  farmerStory: FarmerStory;
}
function FarmerListingCard({ farmerStory, onClick }: FarmerListingCardProps) {
  return (
    <div
      className={
        "FarmerListingCard animate__animated animate__slideInUp animate__faster"
      }
      onClick={onClick}
    >
      <div className={"details"}>
        <div className={"photo"}>
          <img src={farmerStory.story_image} alt={""} />
        </div>
        <CountryCrop country={farmerStory.country} crop={farmerStory.crop} />
        <div className={"farmer-name"}>{farmerStory.farmer_name}</div>
        <div className={"summary"}>
          {farmerStory.summary.replace(/(<([^>]+)>)/gi, "")}
        </div>
      </div>
    </div>
  );
}

export default FarmerListingCard;
