import { ReactComponent as Corn } from "assets/corn.svg";
import Picker, { PickerProps } from "components/Picker";
import { useContext, useEffect, useState } from "react";
import { PointOfInterestContext, PointOfInterestContextType } from "contexts";
import { useFarmerStories } from "hooks/useFarmerStories";
import CropsFilter from "components/crops-picker/CropsFilter";
import "./CropsPicker.scss";
import { Crop } from "models/Crop";
import { FarmerStory } from "models/FarmerStory";

interface RegionPickerProps
  extends Omit<PickerProps, "children" | "pickerIcon" | "pickerLeft"> {}
function CropsPicker({ ...props }: RegionPickerProps) {
  const { setPointOfInterests } = useContext<PointOfInterestContextType>(
    PointOfInterestContext,
  );

  const [showFilter, setShowFilter] = useState(false);
  const [activeCrop, setActiveCrop] = useState<Crop | null>(null);

  const { data: farmerStories } = useFarmerStories();

  useEffect(() => {
    if (props.active) {
      setShowFilter(false);
      if (farmerStories) {
        showCropsOnMap(farmerStories);
      }
    }
  }, [props.active]);

  const onActivateCallback = () => {
    if (props.active) {
      setShowFilter(!showFilter);
    } else {
      setShowFilter(false);
    }
  };

  const handleActiveCrop = (crop: Crop) => {
    setActiveCrop(crop);
    setShowFilter(false);

    // then filter the crop
    if (farmerStories) {
      showCropsOnMap(
        farmerStories.filter((farmerStory) => farmerStory.crop.id === crop.id),
      );
    }
  };

  const showCropsOnMap = (filteredFarmerStories: FarmerStory[]) => {
    // Pass 1: We create a unique map and remove them on duplicates
    const maps: Record<string, FarmerStory> = {};
    filteredFarmerStories.forEach((farmerStory) => {
      maps[`country-${farmerStory.country.id}-crop-${farmerStory.crop.id}`] =
        farmerStory;
    });

    // Pass 2, get the after-unique crops
    const finalFarmerStories: FarmerStory[] = [];
    for (let story in maps) {
      finalFarmerStories.push(maps[story]);
    }

    setPointOfInterests(
      finalFarmerStories.map((farmerStory) => ({
        id: `farmer-${farmerStory.id}-crop-${farmerStory.crop.id}`,
        type: "crop",
        x: ((farmerStory.country.lng + 180) / 360) * 100 - 1.5,
        y: ((90 - farmerStory.country.lat) / 180) * 100 - 3,
        color: farmerStory.crop.color,
        name: farmerStory.crop.name,
        icon: farmerStory.crop.image,
        country: farmerStory.country,
      })),
    );
  };

  return (
    <Picker
      className={"CropsPicker"}
      {...props}
      pickerIcon={<Corn />}
      pickerLeft={112}
      onActivateCallback={onActivateCallback}
    >
      {showFilter && (
        <CropsFilter
          onCrop={handleActiveCrop}
          activeCrop={activeCrop}
          onDismiss={() => setShowFilter(false)}
        />
      )}
      {props.active && !showFilter && (
        <div className={"crops-filter-tooltip"}>
          Click again to see all crops
        </div>
      )}
    </Picker>
  );
}

export default CropsPicker;
