import { ReactNode } from "react";
import "./Footer.scss";

interface FooterProps {
  dark?: boolean;
  children?: ReactNode;
}
function Footer({ dark = false, children }: FooterProps) {
  return (
    <div className={"Footer" + (dark ? " dark" : "")}>
      {children ? (
        children
      ) : (
        <>
          <div className={"important"}>Reimagining Sustainability</div>
          <div>with UPL Farmer Heroes</div>
        </>
      )}
    </div>
  );
}

export default Footer;
