import { useEffect, useRef } from "react";

export function useClickOutside<Element extends HTMLElement>(
  callback: () => void,
) {
  const ref = useRef<Element>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        callback();
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [callback]);

  return ref;
}
