import { MouseEvent, forwardRef, ReactNode, useEffect, useState } from "react";
import "./Overlay.scss";

interface OverlayProps {
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}
const Overlay = forwardRef<HTMLDivElement, OverlayProps>(
  ({ onClick, children }, ref) => {
    const [entered, setEntered] = useState(false);

    useEffect(() => {
      setTimeout(() => {
        setEntered(true);
      });
    }, []);

    return (
      <div
        className={"Overlay " + (entered ? "entered" : "")}
        onClick={onClick}
      >
        {children}
      </div>
    );
  },
);
export default Overlay;
