import './PickerButton.scss';
import {ReactNode} from "react";

interface PickerButtonProps {
  left?: number;
  active?: boolean;
  children: ReactNode;
  onClick?: () => void;
}
function PickerButton ({left = 0, children, active, onClick}: PickerButtonProps) {
  return <div className={"PickerButton" + (active ? ' active' : '')} style={{left}} onClick={onClick}>
    {children}
  </div>
}

export default PickerButton;