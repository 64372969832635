import { createContext, Dispatch, SetStateAction } from "react";
import languages from "data/languages.json";
import { Language } from "models/Language";
import { PointOfInterest, PointOfInterestType } from "models/PointOfInterest";

export const DefaultLanguage: Language = languages[0];

export const MapContext = createContext<PointOfInterestType>("region");

export type PointOfInterestContextType = {
  pointOfInterests: PointOfInterest[];
  setPointOfInterests: Dispatch<SetStateAction<PointOfInterest[]>>;
};
export const PointOfInterestContext = createContext<PointOfInterestContextType>(
  {
    pointOfInterests: [],
    setPointOfInterests: () => {},
  },
);
