import "./CountryListing.scss";
import InlineSVG from "react-inlinesvg";
import { useRegions } from "hooks/useRegions";
import { useCountries } from "hooks/useCountries";
import { useEffect, useMemo, useRef } from "react";
import { Region } from "models/Region";
import { Country } from "models/Country";
import { PointOfInterest } from "models/PointOfInterest";
import regionsData from "data/regions.json";
import Footer from "components/Footer";

type RegionCountries = {
  region: Region;
  countries: Country[];
};

interface CountryListingProps {
  pointOfInterest?: PointOfInterest | null;
  onCountry: (country: Country) => void;
}

function CountryListing({ onCountry, pointOfInterest }: CountryListingProps) {
  const { data: regions } = useRegions();
  const { data: countries } = useCountries();
  const ref = useRef<HTMLDivElement>(null);

  const regionCountries = useMemo<RegionCountries[]>(() => {
    // regroup into listing for us to play!
    const regionCountries: RegionCountries[] = [];
    if (regions && countries) {
      regions.forEach((region) => {
        // hack in color
        const reg = regionsData.find((r) => r.id === region.id);
        if (reg) {
          region.color = reg.color;
        }

        regionCountries.push({
          region: region,
          countries: countries.filter(
            (country) => country.region_id == region.id,
          ),
        });
      });
    }
    return regionCountries;
  }, [regions, countries]);

  useEffect(() => {
    if (regionCountries && regionCountries.length > 0) {
      setTimeout(() => {
        if (ref.current && pointOfInterest) {
          const idx = regionCountries.findIndex(
            (rc) => `reg-${rc.region.id}` === pointOfInterest.id,
          );
          if (idx >= 0) {
            const el = ref.current.children
              .item(0)! // listing el
              .children.item(idx) as HTMLDivElement;
            if (el) {
              ref.current.scrollTo({
                top:
                  el.offsetTop -
                  parseInt(window.getComputedStyle(el).marginTop),
                behavior: "smooth",
              });
            }
          }
        }
      }, 100);
    }
  }, [regionCountries]);

  return (
    <div className={"CountryListing"} ref={ref}>
      <div className={"listing"}>
        {regionCountries.map((rc) => (
          <div className={"region-country"} key={rc.region.id}>
            <div
              className={"region-name"}
              style={{ backgroundColor: rc.region.color }}
            >
              {rc.region.name}
            </div>
            <div className={"countries"}>
              {rc.countries.map((country) => (
                <div
                  className={"country"}
                  key={country.id}
                  onClick={() => onCountry(country)}
                >
                  <div className={"flag"}>
                    <InlineSVG
                      src={`/flags/${country.iso2.toLowerCase()}.svg`}
                    />
                  </div>
                  <div className={"name"}>{country.name}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <Footer dark>
        <div className={"important"}>Advancing agriculture</div>
        <div>with UPL Farmer Heroes</div>
      </Footer>
    </div>
  );
}

export default CountryListing;
