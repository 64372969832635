import Prompt from "components/Prompt";
import { useEffect, useMemo, useState } from "react";
import { FarmerStory } from "models/FarmerStory";
import FarmerListing from "components/prompts/FarmerListing";
import FarmerStoryDetail from "components/prompts/FarmerStoryDetail";
import { PointOfInterest } from "models/PointOfInterest";
import { Crop } from "models/Crop";
import { useCrops } from "hooks/useCrops";
import { Country } from "models/Country";

interface CropPromptProps {
  pointOfInterest?: PointOfInterest | null;
  onClose?: () => void;
  onLogoClick?: () => void;
}
function CropPrompt({
  onClose,
  pointOfInterest,
  onLogoClick,
}: CropPromptProps) {
  const [crop, setCrop] = useState<Crop | null>(null);
  const [country, setCountry] = useState<Country | null>(null);
  const [farmerStory, setFarmerStory] = useState<FarmerStory | null>(null);
  const [farmerStoryIndex, setFarmerStoryIndex] = useState(0);

  const { data: crops } = useCrops();

  const handleCrop = (crop: Crop) => {
    setCrop(crop);
  };

  useEffect(() => {
    if (pointOfInterest && crops) {
      const cropId = pointOfInterest.id.split("-").pop() ?? 0;
      setCrop(crops.find((c) => c.id == cropId) ?? null);
      setCountry(pointOfInterest.country || null);
    }
  }, [pointOfInterest, crops]);

  return (
    <Prompt
      className={"CropPrompt"}
      onClose={onClose}
      onLogoClick={onLogoClick}
    >
      {crop ? (
        <>
          {farmerStory ? (
            <FarmerStoryDetail
              farmerStory={farmerStory}
              farmerStoryIndex={farmerStoryIndex}
              onBack={() => setFarmerStory(null)}
            />
          ) : (
            <FarmerListing
              crop={crop}
              country={country}
              onFarmerStory={(farmerStory, i) => {
                setFarmerStoryIndex(i);
                setFarmerStory(farmerStory);
              }}
            />
          )}
        </>
      ) : (
        <div>Please choose a crop</div>
      )}
    </Prompt>
  );
}

export default CropPrompt;
