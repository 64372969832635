import { css } from "@emotion/css";

interface LogoProps {
  onClick?: () => void;
}
function Logo({ onClick }: LogoProps) {
  return (
    <div
      onClick={onClick}
      className={css`
        color: white;
        display: inline-flex;
        align-items: center;
        img {
          width: 90px;
          display: block;
        }
        span {
          color: white;
          display: block;
          margin-left: 12px;
          font-weight: 600;
          font-size: 1.4em;
          line-height: 1;
        }
      `}
    >
      <img src={"/upl-logo.png"} />
      <span>Farmer Heroes</span>
    </div>
  );
}

export default Logo;
