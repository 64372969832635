import "./CropsFilter.scss";
import { useCrops } from "hooks/useCrops";
import InlineSVG from "react-inlinesvg";
import { Crop } from "models/Crop";
import { useClickOutside } from "hooks/useClickOutside";
import { useEffect, useState } from "react";

interface CropsFilterProps {
  onCrop: (crop: Crop) => void;
  activeCrop: Crop | null;
  onDismiss: () => void;
}
function CropsFilter({ onCrop, activeCrop, onDismiss }: CropsFilterProps) {
  const { data: crops } = useCrops();

  // hack haha..
  const [shown, setShown] = useState(false);
  const ref = useClickOutside<HTMLDivElement>(() => {
    if (shown) {
      onDismiss();
    }
  });

  useEffect(() => {
    // on create set shown so the dismiss won't trigger.
    // Rather bad but work for now
    setTimeout(() => {
      setShown(true);
    }, 200);
  }, []);

  return (
    <div
      ref={ref}
      className={
        "CropsFilter animate__animated animate__fadeInUp animate__faster"
      }
    >
      <div className={"crops-listing"}>
        {crops &&
          crops.length >= 0 &&
          crops.map((crop) => (
            <div
              className={"crop" + (activeCrop === crop ? " active" : "")}
              onClick={() => onCrop(crop)}
              key={crop.id}
            >
              <div className={"icon"} style={{ backgroundColor: crop.color }}>
                <InlineSVG src={crop.image} />
              </div>
              <div className={"name"}>{crop.name}</div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default CropsFilter;
